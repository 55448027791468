export const LEADERBOARD_TYPES = {
  LOCATION: "location",
  COMPANY: "company",
  INDUSTRY: "vertical",
};

export const FEED_SCROLL_POSITION_STORAGE_KEY =
  "spiffy-feed-scroll-position-Z88JB1";

export const MODULE_TYPES = {
  ALL: "all",
  ASSIGNED: "assigned",
  EXTRA_CREDIT: "extraCredit",
};

export const ERROR_CODES = {
  ALREADY_COMPLETED: "already_completed",
  LOCKED_OUT: "module_locked_out",
};
